<template>
    <div class="image-info">
        <div class="image-left">
            <div class="dynamic">
                <div class="head">
                    <div class="user">
                        <img :src="data.user.headIco" class="userIco">
                        <div class="major">
                            <div class="userName">
                                <div>{{data.user.nickName}}</div>
                                <img v-show="data.user.isVip == 1" src="./imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                                <img v-show="data.user.v == 1" src="./imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                                <img v-show="data.user.authenticationStatus >= 2" src="./imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                                <img v-show="data.user.authenticationStatus >= 3" src="./imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                            </div>
                            <div class="userMajor" v-if="data.user.address"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span></div>
                            <div class="userMajor">{{data.creationTime}}</div>
                        </div>
                    </div>

                    <div class="action" v-if="user.uuid == data.user.uuid">
                        <el-dropdown @command="more_action">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="user.uuid == data.user.uuid" command="edit">编辑</el-dropdown-item>

<!--                                <el-dropdown-item v-if="user.uuid == data.user.uuid" command="weChart">微信分享</el-dropdown-item>-->

<!--                                <el-dropdown-item command="share">转移到其他Room</el-dropdown-item>-->

<!--                                <el-dropdown-item command="share">IM给好友</el-dropdown-item>-->

<!--                                <el-dropdown-item command="room">转发朋友圈</el-dropdown-item>-->

                                <el-dropdown-item v-if="user.uuid == data.user.uuid"  command="delete" >删除</el-dropdown-item>

                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <!-- 内容 -->
                <div class="substance">
                    <span class="contentType hand op" @click="toRoom">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                        <span>{{data.roomName}}</span>
                    </span>
					<div v-html="data.content.content" class="html"></div>
                </div>

                <!-- 图片 -->
                <div style="margin-top: 10px" v-if="data.content.images.length !== 0">
                    <div style="width: 100%; height: 100%" v-for="(item, index) in data.content.images" :key="index" >
                        <img :src="item.url" style="width: 100%;height: 100%;">
                        <div style=" margin-bottom: 20px">{{item.describe}}</div>
                    </div>
                </div>

                <!-- 视频 -->
                <div style="margin-top: 10px" v-if="data.content.videos.length !== 0">
                    <video-player  class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                </div>

                <!-- 文件 -->
                <div style="margin-top: 10px" v-if="data.content.enclosure.length !== 0">
                    <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in data.content.enclosure" :key="index">
                        <img src="./imgs/files.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                        <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                    </div>
                </div>

              <div style="margin: 20px">
                <DynamicOperation :data="data"></DynamicOperation>
              </div>
            </div>
        </div>

        <div class="image-right">

        </div>

        <!--		编辑知识仓库视频-->
        <div v-if="data.content.bgbType === 8">
          <!--编辑知识仓库视频格式-->
          <el-dialog  width="482px" :visible.sync="editOpenKw" :show-close=false class="upload_att">
            <addUploadVideo @custom-event="closeModule" :menuId=this.data.menuId :edit-id="this.data.content.kwId"></addUploadVideo>
          </el-dialog>
          <!--编辑菜单类型视频菜单-->
          <el-dialog  width="482px" :visible.sync="editOpenMenu" :show-close=false class="upload_att">
            <menuVideo @custom-event="closeModule" :menuId=this.data.menuId :edit-id="this.data.id"></menuVideo>
          </el-dialog>
        </div>

      <!--		编辑知识仓库图片-->
      <div v-if="data.content.bgbType === 7">
        <!--		编辑知识仓库图片格式-->
        <div v-if="editOpenKw == true">
          <add-image-file-base-form :is-show="editOpenKw" :on-close="closeModule" :menuId=this.data.menuId  :edit-id="this.data.content.kwId" ></add-image-file-base-form>
        </div>

        <!--		编辑菜单图片类型菜单-->
        <div v-if="editOpenMenu == true">
          <menu-image :is-show="editOpenMenu" :on-close="closeModule" :menuId=this.data.menuId  :edit-id="this.data.id" ></menu-image>
        </div>
      </div>

      <!--		编辑知识仓库附件-->
      <div v-if="data.content.bgbType === 9">
        <!--编辑知识仓库附件格式-->
        <el-dialog  width="705px" :visible.sync="editOpenKw" :show-close=false class="upload_att">
          <add-upload-attachment @custom-event="closeModule" :menuId=this.data.menuId :edit-id="this.data.content.kwId"></add-upload-attachment>
        </el-dialog>
        <!--编辑菜单类型附件菜单-->
        <el-dialog  width="705px" :visible.sync="editOpenMenu" :show-close=false class="upload_att">
          <menu-enclosure @custom-event="closeModule" :menuId=this.data.menuId :edit-id="this.data.id"></menu-enclosure>
        </el-dialog>
      </div>
    </div>
</template>

<script>
    import Release from "@/pages/room/components/release.vue";
    import addUploadAttachment from "./knowledge/components/addUploadAttachment";
    import DynamicOperation from "@/pages/room/components/DynamicOperation.vue";
    import menuVideo from "@/pages/room/components/menuModule/menuVideo.vue";
    import addUploadVideo from "@/pages/room/knowledge/components/addUploadVideo.vue";
    import menuImage from "@/pages/room/components/menuModule/menuImage";
    import AddUploadAttachment from "@/components/common/addUploadAttachment.vue";
    import menuEnclosure from "@/pages/room/components/menuModule/menuEnclosure";
    import addImageFileBaseForm from "@/pages/room/knowledge/components/addImageFileBaseForm.vue";

    export default {
        name: "imageInfo",
        components: {
          addImageFileBaseForm,
          AddUploadAttachment,
          addUploadVideo, menuVideo,menuImage, DynamicOperation, Release,addUploadAttachment,menuEnclosure},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                data: {
                    user: {},
                    content: {
                        images: [],
                        videos: [],
                        enclosure: []
                    }
                },
                content_list: [],
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: '' // url地址
                    }],
                    poster: '', // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                },
                editOpen:false,
                kwImage:this.data,
              editOpenKw:false,
              editOpenMenu:false,
            }
        },
        mounted() {
            let that = this;
            this.$nextTick(function () {
                this.$EventBus.$on('changeData', () => {
                    that.getRoomContent()
                });
            });
        },

        methods: {
          //关闭编辑视频组件
          closeModule() {
            // 在父组件中监听自定义事件
            this.editOpenKw = false;
            this.editOpenMenu = false;
            this.getRoomContent()
          },
            // 跳转到Room
            toRoom() {
                this.$router.push({ path: '/room/info/' + this.data.roomId})
            },

            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },

            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                            this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
                                if(ret.isSuccess == 1){
                                    that.utils.sus(ret.data);
                                    that.show = false;
                                }
                            })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }

                if (e == "edit") {
                  if(this.data.isKwFormat == 0){
                    that.editOpenMenu =true;

                  }else if(this.data.isKwFormat == 1){
                    that.editOpenKw =true;
                  }
                }
            },

            // 获取内容
            getRoomContent() {
                var that = this;
                this.newApi.getRoomContentInfo({id: this.$route.params.id}).then(res =>{
                    that.data = res.data;
                    that.kwImage = res.data;
                    if (res.data.content.videos.length !== 0) {
                        this.playerOptions.sources[0].src = res.data.content.videos[0].url;
                        this.playerOptions.poster = res.data.content.videos[0].url+'?x-oss-process=video/snapshot,t_0,f_jpg';
                    }
                })
            },
        },

        beforeMount() {
            this.getRoomContent();
        },
    }
</script>

<style scoped>
    .describe{
        margin-top: 16px;
        font-size: 16px;
        font-family: FZLanTingHeiS-M-GB-Regular, FZLanTingHeiS-M-GB;
        font-weight: 400;
        color: #606060;
        line-height: 24px;
    }
    .image-info {
        margin: 0 auto;
        width: 1500px;
        min-height: 100vh;
        display: flex;
    }

    .image-left {
        width: 74%;
        margin-right: 10px;
    }

    .image-right {
        width: 25%;
        padding: 10px 16px;
        margin-bottom: 10px;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .image-info .dynamic{
        padding: 24px 38px 10px;
        margin-bottom: 10px;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }
    .image-info .dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .image-info .dynamic .head .user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .image-info .dynamic .head .user .userIco{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
    }
    .image-info .dynamic .head .user .major .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items:flex-end;
    }
    .image-info .dynamic .head .user .major .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 5px;

    }
    .image-info .dynamic .substance{
        margin-top: 20px;
        line-height: 26px;
        font-size: 15px;
    }
    .image-info .dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .image-info .dynamic .substance .t{
        color: #555555;
    }
    .image-info .dynamic .imgs{
        margin-top: 14px;
    }
    .image-info .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .image-info .dynamic .volume{
        height: 35px;
        line-height: 35px;
        color: #999999;
        font-size: 14px;
    }
    .image-info .dynamic .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .image-info .dynamic .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }
    .image-info .dynamic .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }
    .image-info .mr5{
        margin-right: 5px;
    }


</style>
<style>
.html img{
	width: 100% !important;
	height: auto !important;
}
</style>
